import React, { useState, useEffect } from 'react';
import './SCAdmin_Auth.css'
import APIService from "../../APIService";

const LoginForm = ({ onLoginSuccess }) => {


  const [error, setError] = useState('');
  // const [csrfToken, setCsrfToken] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await APIService.scAdminLogin(email, password)
      const res = await response.json()
        if (response.status == 401) {
          setError("Incorrect Email or Password");
        } else if (response.ok) {
          sessionStorage.setItem("authToken", res.verified);
          onLoginSuccess(res.verified);
        }
    }
    catch (error) {
      console.error('Error during login:', error);
      setError('An error occurred during login.');
    }
  };



      return (
        <form className="loginForm" onSubmit={handleSubmit}>
                  {error && <p className="error">{error}</p>}
            <label htmlFor="email">Email</label>
            <div className="inputField">
              <input type="text" name="email" className="inputField__input" value={email} onChange={handleEmailChange}/>
            </div>
            <label htmlFor="password">Password</label>
            <div className="inputField">
              <input id="passwordField" type="password" name="password" className="inputField__input" value={password} onChange={handlePasswordChange}/>
            </div>
            <button className="mainBtn" type="submit">LOGIN</button>
        </form>
      )
}

export default LoginForm;
